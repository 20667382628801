import { useStaticQuery, graphql } from "gatsby";
import React, { createContext, useReducer } from "react";

const getPages = (articleCount) => {
  const ITEMS_PER_PAGE = 4;
  const remainder = articleCount % ITEMS_PER_PAGE;

  return Math.floor(articleCount / ITEMS_PER_PAGE) + (remainder > 0 ? 1 : 0);
};

const BlogDataProvider = ({ children }) => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicBlog(
        limit: 10
        sort: { fields: first_publication_date, order: DESC }
      ) {
        edges {
          node {
            uid
            first_publication_date
            data {
              banner_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              preview_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              banner_title {
                text
              }
              banner_preview_text {
                raw
                text
              }
            }
            tags
          }
        }
      }
      nwpLogo: file(relativePath: { eq: "NWP-Logo-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `);

  const [blogData, dispatch] = useReducer(reducer, {
    articles: [...queryData.allPrismicBlog.edges],
    filteredArticles: queryData.allPrismicBlog.edges.slice(0, 4),
    page: 1,
    category: "",
    pages: getPages(queryData.allPrismicBlog.edges.length),
    nwpLogo: queryData.nwpLogo.childImageSharp,
  });

  return (
    <BlogDataContext.Provider value={{ blogData, dispatch }}>
      {children}
    </BlogDataContext.Provider>
  );
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "FILTER_PAGE": {
      const index = action.page * action.limit - action.limit;
      return {
        ...state,
        filteredArticles: state.articles.slice(index, index + action.limit),
        page: action.page,
        category: "",
        pages: getPages(state.articles.length),
      };
    }
    case "FILTER_CATEGORY": {
      const index = 0;
      const filtered =
        action.category === "Uncategorized"
          ? state.articles
              .filter((article) => {
                return article.node.tags.length === 0;
              })
              .slice(0, 10)
          : state.articles
              .filter((article) => {
                return article.node.tags.includes(action.category);
              })
              .slice(index, index + action.limit);

      return {
        ...state,
        category: action.category,
        pages: getPages(filtered.length),
        filteredArticles: filtered,
      };
    }
    default:
      return state;
  }
};

export const BlogDataContext = createContext();
export default BlogDataProvider;
