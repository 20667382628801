import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const RelatedPosts = ({ relatedContent }) => {
  const allBlog = useStaticQuery(graphql`
    query Related {
      allPrismicBlog {
        edges {
          node {
            uid
            first_publication_date
            data {
              banner_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              banner_title {
                text
              }
            }
          }
        }
      }
      nwpLogo: file(relativePath: { eq: "NWP-Logo-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  const related = allBlog.allPrismicBlog.edges.filter((blog) => {
    return relatedContent?.indexOf(blog.node.uid) >= 0;
  });

  return (
    related.length > 0 && (
      <div className="flex flex-col bg-gray2 px-5 py-9 space-y-9">
        {related.map((related, index) => {
          const publishDate = new Date(related.node.first_publication_date);
          const image = getImage(
            related.node.data.banner_image.localFile !== null
              ? related.node.data.banner_image.localFile
              : allBlog.nwpLogo
          );
          return (
            <div className="bg-white pt-2 px-8 pb-5" key={`related${index}`}>
              <GatsbyImage
                image={image}
                alt="Related"
                objectFit="contain"
                className="w-full h-32"
              />
              <p className="pt-3 font-semibold">
                {related.node.data.banner_title.text}
              </p>
              <p className="text-xs text-gray3">
                {publishDate.toLocaleDateString("en-US")}
              </p>
            </div>
          );
        })}
      </div>
    )
  );
};

export default RelatedPosts;
