import React from "react";
import { graphql, Link } from "gatsby";
import BlogContainer from "../../../components/container/BlogContainer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CategoryItems = ({ data, index }) => {
  return (
    <div className="mb-20">
      <h2 className="mb-5 font-semibold">
        <Link to={`/blog/${data.uid}`}>{data.data.banner_title.text}</Link>
      </h2>
      <GatsbyImage
        key={`image${index}`}
        image={getImage(data.data.banner_image.localFile)}
        alt={
          data.data.banner_image.alt !== null
            ? data.data.banner_image.alt
            : "Banner Image"
        }
        className="mb-7"
      />
      <div
        className="mb-2"
        dangerouslySetInnerHTML={{ __html: data.data.banner_preview_text.html }}
      />
      <div className="blog-div">
        <Link to={`/blog/${data.uid}`}>Read more...</Link>
      </div>
    </div>
  );
};

const BlogTagPage = ({ tag, data }) => {
  const filteredData =
    tag === "Uncategorized"
      ? data.allPrismicBlog.edges
          .filter((edge) => {
            return edge.node.tags.length === 0;
          })
          .slice(0, 10)
      : data.allPrismicBlog.edges
          .filter((edge) => {
            return edge.node.tags.includes(tag);
          })
          .slice(0, 10);

  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <BlogContainer parentText="Blog" title={`${tag}`}>
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => {
            return (
              <CategoryItems
                data={item.node}
                key={`CategoryItem${index}`}
                index={index}
              />
            );
          })
        ) : (
          <h1>No article(s) found for this category.</h1>
        )}
      </BlogContainer>
    </div>
  );
};

export const categoryQuery = graphql`
  query {
    allPrismicBlog(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          uid
          data {
            banner_title {
              text
            }
            banner_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    quality: 100
                    formats: [AUTO, WEBP]
                  )
                }
              }
              alt
            }
            banner_preview_text {
              html
            }
          }
          tags
        }
      }
    }
  }
`;

export default BlogTagPage;
