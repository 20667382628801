import React, { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { BlogDataContext } from "../../../data/BlogDataContext";

const PrismicCategories = () => {
  const { blogData, dispatch } = useContext(BlogDataContext) || {blogData: {}, dispatch: null};

  const selectCategory = (newCategory, blogData) => {

    if (blogData.category === newCategory) {
      dispatch({
        type: "FILTER_PAGE",
        page: blogData.page,
        limit: 4,
        blogData,
      });
    } else {
      dispatch({
        type: "FILTER_CATEGORY",
        category: newCategory,
        limit: 4,
        blogData,
      });
    }
  };

  return (
    <div className="bg-gray2 pt-8 pl-14 pr-5 pb-14">
      <h3 className="font-semibold mb-4">Categories</h3>

      <StaticQuery
        query={graphql`
          query PrismicCategories {
            allPrismicBlog {
              distinct(field: tags)
            }
          }
        `}
        render={(data) => {
          return (
            <div className="flex flex-col space-y-3 items-start">
              {data.allPrismicBlog.distinct.map((node, index) => {
                return (
                  <button
                    className={`category-item ${
                      blogData.category === node ? "text-blue-600" : ""
                    }`}
                    key={`category${index}`}
                    onClick={(e) => {
                      e.preventDefault();
                      selectCategory(node, blogData);
                    }}
                  >
                    {node}
                  </button>
                );
              })}
              <button
                className={`category-item ${
                  blogData.category === "Uncategorized" ? "text-blue-600" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  selectCategory("Uncategorized", blogData);
                }}
              >
                Uncategorized
              </button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default PrismicCategories;
