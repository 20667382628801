import React, { useState } from "react";
import "reactjs-popup/dist/index.css";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

const FormInput = styled.input`
  margin-top: 1px;
  padding: 0.5rem;
  background: transparent;
  //border: 1px solid #ccc;
  border-bottom: 1px solid #ffffff;
  // border-radius: 6px;
  // background: #f5f5f5;
  width: 100%;

  :focus {
    border-color: #7eb0db;
    outline: 1px solid transparent;
    outline-offset: 2px;
  }
`;

const BlogSubscribeForm = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [mailChimpStatus, setMailChimpStatus] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsSaving(true);

    addToMailchimp(data.email, {
      FNAME: data.firstname,
      LNAME: data.lastname,
      MMERGE3: data.company,
    })
      .then((data) => {
        setIsSaving(false);
        setMailChimpStatus(data);
        setTimeout(() => {
          setMailChimpStatus();
        }, 2000);
      })
      .catch((error) => {
        setIsSaving(false);
        setMailChimpStatus({
          result: "error",
          msg: error.toString(),
        });

        setTimeout(() => {
          setMailChimpStatus();
        }, 2000);
      });
  };

  return (
    <div className="flex items-center justify-center mt-5 ">
      <div className="w-full">
        <div className="px-12 bg-actionBlue text-green-50 pt-6 pb-2">
          <p className="mb-12 text-xl font-bold">Subscribe to our newsletter</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-y-4 items-start mb-14">
              <FormInput
                type="text"
                className="blog-input"
                placeholder="First Name"
                {...register("firstname", { required: true })}
                onChange={(e) => {
                  setValue("firstname", e.target.value);
                }}
              />
              {errors.firstname && (
                <span role="alert" className="text-xs text-red-600 font-sans">
                  Field is required
                </span>
              )}
              <FormInput
                type="text"
                className="blog-input"
                placeholder="Last Name"
                {...register("lastname", { required: true })}
                onChange={(e) => {
                  setValue("lastname", e.target.value);
                }}
              />
              {errors.lastname && (
                <span role="alert" className="text-xs text-red-600 font-sans">
                  Field is required
                </span>
              )}
              <FormInput
                type="text"
                className="blog-input"
                placeholder="Company Name"
                {...register("company", { required: true })}
                onChange={(e) => {
                  setValue("company", e.target.value);
                }}
              />
              {errors.company && (
                <span role="alert" className="text-xs text-red-600 font-sans">
                  Field is required
                </span>
              )}
              <FormInput
                type="email"
                className="blog-input"
                placeholder="Email Address"
                {...register("email", { required: true })}
                onChange={(e) => {
                  setValue("email", e.target.value);
                }}
              ></FormInput>
              {errors.email && (
                <span role="alert" className="text-xs text-red-600 font-sans">
                  Field is required
                </span>
              )}
              <button
                className="mt-8 border-white border-1 py-2 px-12 text-white rounded-lg hover:bg-white hover:text-actionBlue focus:outline-none"
                type="submit"
              >
                Submit
              </button>

              <div
                className={`${
                  isSaving ? "block" : "hidden"
                } text-white flex flex-col w-full items-center justify-center`}
              >
                <p>Subscribing...</p>
              </div>
              {mailChimpStatus && (
                <div
                  className={`${
                    mailChimpStatus.result === "error"
                      ? "text-red-700"
                      : "text-white"
                  }  w-full max-w-xs`}
                >
                  {mailChimpStatus.msg}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlogSubscribeForm;
